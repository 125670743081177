@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/font/PTSans-Regular.ttf');
}

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/font/PTSans-Bold.ttf');
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* * {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:focus {
  outline: none !important;
} */


*:not(i),
body {
  font-family: "PT Sans", sans-serif !important;
}

.login-google {
  border: 1px solid #E3E6E9!important;
  width: 100%!important;
  box-shadow: unset!important;
  color: #384259!important;
  height: 45px!important;
}

.chart-container .apexcharts-series:nth-child(2) > path{
  fill: #878787 !important;
}
.chart-container .apexcharts-series:nth-child(1) > path:first-child{
  fill: red !important;
}
.chart-container .apexcharts-series:nth-child(1) > path:nth-child(2){
  fill: #E5C311 !important;
}
.chart-container .apexcharts-series:nth-child(1) > path:nth-child(3){
  fill: #008000 !important;
}
.chart-container .apexcharts-series:nth-child(1) > path:nth-child(4){
  fill: #0000FF !important;
}

.apexcharts-tooltip{
  white-space: normal !important;
  max-width: 400px;
  min-width: 200px;
  z-index: 9999 !important;
}

.team-dynamics-chart-wrapper{
  position: relative;
  height: 480px;
}
.team-dynamics-chart-wrapper::before{
  content: '';
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: url('../assets/team_dynamics_chart.png');
  background-size: 480px 480px;
  background-position: center;
  background-repeat: no-repeat;
}
.team-dynamics-chart-wrapper{
  padding: 15px;
}
.team-dynamics-chart-wrapper #ac-chart-container>div{
  height: 400px !important;
}
.team-dynamics-chart-wrapper .anychart-credits{
  display: none;
}
.team-dynamics-chart-wrapper *[stroke="#CECECE"]{
  display: none;
}
.team-dynamics-chart-wrapper path[fill="#ffffff"]{
  display: none;
}
.team-dynamics-chart-wrapper g[id^="ac_unmanageable-layer"]{
  display: none;
}
.team-dynamics-chart-tooltip{
  display: flex;
  align-items: center;
}
.td-chart-image{
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 1000px;
  overflow: hidden;
  margin-right: 14px;
}
.td-chart-image img{
  display: inline-block;
  height: 50px;
  width: 50px;
  object-fit: cover;
  object-position: center;
}
.team-dynamics-chart-wrapper .anychart-tooltip{
  background: #ffffff;
  color: #384259;
  border: 1px solid #eeeeee;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
  padding: 15px 17px;
  border-radius: 10px;
}

.team-dynamics-chart-wrapper .anychart-tooltip span{
  display: block;
}
.team-dynamics-chart-wrapper .anychart-tooltip span.font-bold{
  font-size: 16px;
  font-weight: bold;
}
.team-dynamics-chart-wrapper .anychart-tooltip-separator{
  display: none;
}
.team-dynamics-chart-wrapper .anychart-tooltip>div:not(.anychart-tooltip-title){
  display: flex;
  margin-top: 16px;
}
.team-dynamics-chart-wrapper .anychart-tooltip>div:not(.anychart-tooltip-title) .bar{
  width: 5px;
  min-width: 5px;
  margin-right: 15px;
}
.MuiOutlinedInput-root{
  padding: 3px !important;
  min-height: 49px !important;
  padding-left: 9px !important;
}
.login-form-container{
  padding-left: 150px;
  padding-right: 150px;
}
.header .header-normal-btn{
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  color: #384259 !important;
  width:auto !important;
}
.header .header-normal-btn.normal-btn-business:hover{
  color: #954A89 !important;
}
.header .header-normal-btn.normal-btn-coaching:hover{
  color: #7AB794 !important;
}
.header .header-contact-btn{
  color: #ffffff !important;
  padding: 10px 15px !important;
  outline: none!important;
  font-weight: bold;
  width: auto !important;
  height: auto !important;
  /* background-color: #384259 !important; */
  border-radius: 5px !important;
}
.header .header-module-switch-btn{
  color: #ffffff !important;
  padding: 10px 15px !important;
  outline: none!important;
  font-weight: bold;
  width: auto !important;
  height: auto !important;
  border-radius: 5px !important;
}
@media all and (min-width:1400px){
  .header-container{
    max-width: 1140px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    width:100%;
    margin: 0 auto;
  }
}
.login-reg-container{
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}
.login-reg-wrapper{
  display: flex;
}
.login-reg-wrapper>div{
  width: 50%;
}
.swiper-parent{
  padding-top: 50px;
  padding-bottom: 50px;
}
.form-section-container{
  padding: 50px 100px;
}
@media all and (max-width:575px){
  .header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .header-module-switch-btn{
    width: calc(100% - 22px) !important;
  }
  .login-reg-wrapper{
    flex-direction: column;
  }
  .login-reg-wrapper>div{
    width: 100%;
  }
  .form-section-container{
    padding: 50px 15px;
  }
  .team-dynamics-chart-wrapper::before{
    background-size: 345px 345px;
  }
}
.swiper-parent .swiper-pagination-bullets,
.swiper-parent .swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-parent .swiper-pagination-custom, 
.swiper-parent .swiper-pagination-fraction{
  bottom: 0px !important;
}
/* footer css start */
footer .container {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.footer {
  background-color: #1C1D1F;
  padding: 50px 0px 15px;
}

.footer .main-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer .main-footer .footer-widgets {
  width: 25%;
  padding: 10px;
}

.footer .main-footer #footer-widgets-one {
  text-align: center;
}

a.icon-op-0 i{
  opacity: 0;
  visibility: hidden;
}

.footer .main-footer .footer-widgets p {
  margin-bottom: 30px;
  line-height: 22px;
  font-size: 14px;
}

.footer .main-footer .footer-logo {
  height: 115px;
  width: auto;
  margin: 0 auto 10px auto;
}

.footer .main-footer .footer-widgets .text,
.footer .main-footer .footer-widget-title,
.footer .main-footer p,
.footer .main-footer .social-icon-item {
  color: #fff;

}

.footer .main-footer .footer-widget-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5em;
    margin-bottom: 20px;
}

.footer .main-footer .footer-widgets .list-item {
  padding: 0px;
  list-style-type: none;
}

.footer .main-footer #footer-widgets-two {
  padding-left: 25px;
}

.footer .main-footer .footer-widgets .list-item.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
}

.footer .main-footer .social-icon-style a {
  background: #414244;
  height: 41px;
  width: 41px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-decoration: none;
  margin: 0px 10px;
}

.footer .main-footer .footer-widgets .menu-list {
  text-decoration: none;
  padding: 10px 0px 10px 0px;
  line-height: 22px;
}

.footer .main-footer .footer-widgets .menu-list a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.footer .main-footer .coaching-style .menu-link:hover,
.footer .main-footer .coaching-style .menu-list a:hover {
  color: #7ab794;
}

.footer .main-footer .business-style .menu-link:hover,
.footer .main-footer .business-style .menu-list a:hover {
  color: #954a89;
}

.footer .main-footer .info-icon .menu-list:hover i {
  color: #fff;
}

.footer .main-footer a.info-widget span {
  padding-left: 20px;
  font-size: 15px;
}

.footer .main-footer a.info-widget i {
  font-size: 18px;
}

.footer .bottom-section{
  padding-top: 30px;
}

.footer .bottom-section p {
  color: #fff;
  text-align: center;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 1rem;
}

/* footer css end */
/*09. Media Query start*/
@media screen and (min-width: 992px) {}

@media (max-width: 1024px) and (min-width: 768px) {}

@media screen and (min-width: 768px) {}

@media screen and (max-width: 1199px) {
  #header.header {
      padding: 10px 20px;
  }
}

/*Tablet*/
@media screen and (max-width: 991px) {}

/*Small Device*/
@media screen and (max-width: 767px) {
  #header .navbar {
      flex-direction: row-reverse;
  }

  .footer {
      padding: 50px 20px;
  }

  .footer .main-footer {
      flex-wrap: wrap;
  }

  .footer .main-footer #footer-widgets-one {
      width: 100%;
  }

  .footer .main-footer #footer-widgets-two,
  .footer .main-footer #footer-widgets-three {
      width: 50%;
      margin: 30px 0px;
  }

  .footer .main-footer #footer-widgets-four {
      width: 100%;
      margin-bottom: 30px;
  }
}
.team-tooltip .MuiTooltip-tooltip{
  background-color: transparent !important;
}
.tooltip-list{
  list-style: disc;
  margin-left: 20px;
}
.team-dynamics-modal-swiper .swiper-slide{
  background:transparent;
  text-align: left;
  margin-bottom: 40px;
}
.team-dynamics-modal-swiper .swiper-slide p{
  margin-bottom: 15px;
}
.team-dynamics-modal-swiper .swiper-pagination-bullets, 
.team-dynamics-modal-swiper .swiper-pagination-bullets.swiper-pagination-horizontal, 
.team-dynamics-modal-swiper .swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: 10px;
}
.react-custom-tooltip{
  padding: 0px !important;
  background: #4D5871 !important;
  opacity: 1 !important;
  z-index: 9 !important;
  max-width: 300px;
}

.dashboard-candidate-detail-chart .apexcharts-tooltip {
  left: auto !important;
}

/* tooltip font size */
.tooltip-font-desc{
  font-size: 14px;
  
  }

/* Candidate-list and Workforce Search input */
.candidate-list-and-workforce-search form {
  border: 2px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: none;
}

/* .ploating-polar-chart svg g > g > g:nth-child(1),
.ploating-polar-chart svg g > g > g:nth-child(2),
.ploating-polar-chart svg g > g > g:nth-child(3),
.ploating-polar-chart svg g > g > g:nth-child(4)  {
  transform: rotate(45deg);
  transform-origin: center;
} */

.ploating-polar-chart .anychart-credits {
  display: none;
}

.ploating-polar-chart {
  padding: 16px;
}

.ploating-polar-chart .anychart-tooltip{
  background: #ffffff;
  color: #384259;
  border: 1px solid #eeeeee;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
  padding: 15px 17px;
  border-radius: 10px;
}

.ploating-polar-chart .anychart-tooltip span{
  display: block;
}
.ploating-polar-chart .anychart-tooltip span.font-bold{
  font-size: 16px;
  font-weight: bold;
}
.ploating-polar-chart .anychart-tooltip-separator{
  display: none;
}
.ploating-polar-chart .anychart-tooltip>div:not(.anychart-tooltip-title){
  display: flex;
  margin-top: 16px;
}
.ploating-polar-chart .anychart-tooltip>div:not(.anychart-tooltip-title) .bar{
  width: 5px;
  min-width: 5px;
  margin-right: 15px;
}

.ploating-polar-chart {
  position: relative;
  height: 480px;
}

.ploating-polar-chart::before{
  content: '';
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0px;
  right: 5px;
  background: url('../assets/team-chemistry-plotting-location-and-presentation.png');
  background-size: 425px 425px;
  background-position: center;
  background-repeat: no-repeat;
}

.ploating-polar-chart #ac-chart-container div {
  top: 26px !important;
}

.ploating-polar-chart svg g g g:nth-child(1)  {
  display: none;
}

.ploating-polar-chart svg g ~ path  {
  display: none;
}

.ploating-polar-chart svg g > g > g:nth-child(2),
.ploating-polar-chart svg g > g > g:nth-child(3),
.ploating-polar-chart svg g > g > g:nth-child(4) {
  display: none;
}

@media all and (max-width:526px){
  .ploating-polar-chart::before{
    background-size: 407px 407px;
  }
}

@media all and (max-width:500px){
  .ploating-polar-chart::before{
    background-size: 342px 342px;
  }
}

@media all and (max-width:400px){
  .ploating-polar-chart::before{
    background-size: 240px 240px;
  }
}

@media all and (max-width:375px){
  .ploating-polar-chart::before{
    background-size: 230px 230px;
  }
}