.semiCircleChart {
  position: relative;
  width: 150px;
  height: 75px;
}

.semiCircleChart::before {
  position: absolute;
  content: "";
  width: inherit;
  height: inherit;
  border: 19px solid transparent;
  border-bottom: none;
  border-top-left-radius: 175px;
  border-top-right-radius: 175px;
}

.semiCircleChart .bottom {
  left: 50%;
  top: 20px;
  position: absolute;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.semiCircleChart .bottom .image {
  height: 110px;
  width: 110px;
  border-radius: 100%;
  background-color: aqua;
  border: solid white 14px;
  z-index: 8;
}
.semiCircleChart .bottom .text {
  margin-top: -50px;
  padding-top: 50px;
  z-index: 6;
  width: 200px;
  height: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-items: start;
  flex-direction: column;
}

.semiCircleChart .item {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 19px solid;
  border-top: none;
  border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px;
  transform-origin: 50% 0;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.semiCircleChart .chatBox {
  position: absolute;
  top: 25px;
  left: -80px;
  height: 58px;
  width: 58px;
  padding: 5px;
  border-radius: 100%;
  background-color: green;
  color: #fff;
  font-weight: 500;
}
.semiCircleChart .arrow {
  position: absolute;
  top: 33px;
  left: -42px;
  height: 18px;
  width: 18px;
  transform: rotate(101deg);
}
.semiCircleChart .item:nth-child(1) {
  z-index: 4;
  transform: rotate(45deg);
}
.semiCircleChart .item:nth-child(1) .chatBox {
  z-index: 4;
  transform: rotate(-45deg);
}
.semiCircleChart .item:nth-child(2) {
  z-index: 3;
  transform: rotate(90deg);
}
.semiCircleChart .item:nth-child(2) .chatBox {
  z-index: 3;
  transform: rotate(-90deg);
}
.semiCircleChart .item:nth-child(3) {
  z-index: 2;
  transform: rotate(135deg);
}
.semiCircleChart .item:nth-child(3) .chatBox {
  z-index: 2;
  transform: rotate(-135deg);
}

.semiCircleChart .item:nth-child(4) {
  z-index: 1;
  transform: rotate(180deg);
}
.semiCircleChart .item:nth-child(4) .chatBox {
  z-index: 1;
  transform: rotate(-180deg);
}
