@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

:root {
  --primary: #1a1736;
  --primary-light: #2a2650;
  --secondary: #242041;
  --secondary-light: #242041;
  --success: #7AB794;
  --danger: #DC2626;
  --dangerLight: #FECACA;
  --grayLight: #E3E6E9;
  --purple: #954A89;
  --purpleLight: #B07FAA;
  --blue: #009CDE;
  --primary-black: #828282;
}

*{
  box-sizing: border-box;
}

body {
  background-color: #F8F9FA;
}
.MuiTooltip-tooltip {
  padding: 0!important;
}
.MuiTooltip-arrow::before  {
  background-color: #4D5871!important;
}